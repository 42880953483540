<template>
  <div>
    <v-alert
      v-if="business.pixel_approved_at"
      :value="true"
      type="success"
      class="mb-3"
    >
      PIXEL TRACKING APPROVED!
    </v-alert>

    <v-alert
      v-if="business.promotion_code_tracking_approved_at"
      :value="true"
      type="success"
      class="mb-3"
    >
      PROMO' CODE TRACKING APPROVED!
    </v-alert>

    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">Intro</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2"
          >Pixel integration</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="3">Pixel test</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-switch
            v-model="isWebshop"
            label="Is the business a webshop?"
          ></v-switch>
          <v-select
            v-if="isWebshop"
            label="What system are they using?"
            v-model="selectedShop"
            :items="shopOptions"
            style="max-width: 400px"
          ></v-select>

          Click "Start process" to continue. This will create the demo campaign used to test with.
          <br />

          <v-btn
            color="primary"
            :disabled="isWebshop && !selectedShop"
            @click="startProcess()"
          >
            Start process
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div v-if="this.onlyBid">
            <div class="subheading font-weight-bold">How does it work?</div>
            <p>
              Send the
              <a
                href="https://makeinfluence.notion.site/Make-Influence-API-74a53ce2882d4b18bd7b922b8df84ca7"
                target="_blank">
                API documentation
              </a> to their developers. This explains how to integrate our tracking codes.
            </p>
          </div>
          <template v-else>
            <v-container fluid grid-list-lg class="pt-0">
              <v-layout row wrap>
                <v-flex sm6>
                  <div class="subheading font-weight-bold">How does it work?</div>
                  <ol>
                    <li>
                      Ret "Værdi" og "Ordre nr." variablen til det stykke kode der
                      indeholder ordrens værdi/nr. Det kan f.eks være
                      <code v-text="'<?= $orderValue ?>'"></code>.
                    </li>
                    <li>Kopier tracking koden.</li>
                    <li>
                      Tracking koden placeres i
                      <code v-text="'<body>'"></code> tagget på kvitteringssiden.
                    </li>
                  </ol>
                </v-flex>
                <v-flex sm6>
                  <v-text-field
                    v-model="valueVar"
                    label="Værdi variabel"
                    hint="Den variabel der indeholder ordrens fulde pris"
                    >Værdi variabel</v-text-field
                  >
                  <v-text-field
                    v-model="uidVar"
                    label="Ordre nr. variabel"
                    hint="Den variabel der indeholder ordrens unikke ID"
                    >Ordre variabel</v-text-field
                  >
                  <v-text-field
                    v-model="promotionCodeVar"
                    label="Rabat kode"
                    hint="Den variabel der indeholder ordrens unikke ID"
                    >Ordre rabat kode</v-text-field
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-container>
          </template>

          <div class="mb-5" v-if="onlyBid">
            <h3>Business ID</h3>

            <code v-text="business.uuid"></code>
          </div>
          <div class="mb-5" v-else>
            <h3>Tracking pixel</h3>

            <code>{{ imagePixel }}</code>
          </div>

          <v-btn @click="step = 1">
            Back
          </v-btn>
          <v-btn
            color="primary"
            @click="
              step = 3;
              refreshTableData();
            "
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row class="mb-3">
              <v-col v-if="!business.pixel_approved_at && campaign.uuid">
                <div class="headline mb-3">Pixel tracking</div>
                <!--Show short link-->
                <v-text-field
                   v-model="redirectLink"
                   label="Redirect link"
                   outlined
                   class="mb-3"
                ></v-text-field>

                <!--Show short link-->
                <v-text-field
                   label="Tracking link"
                   :value="trackingLink"
                   id="pixelTrackingInput"
                   hint="Click to copy"
                   persistent-hint
                   outlined
                   class="mb-3"
                >
                  <template slot="append">
                    <v-icon @click="copyElementText('pixelTrackingInput')">
                      fal fa-copy
                    </v-icon>
                  </template>
                </v-text-field>

                <p>
                  Send the tracking link, and have the customer make a conversion. If visible on the list, the tracking can be approved. You can verify the tracked values with the customer.
                </p>
              </v-col>

              <v-col xs v-if="!business.promotion_code_tracking_approved_at">
                <div class="headline mb-3">Promo' code tracking</div>

                <p>
                  Make a conversion with the promotion code <b><u>makeinf10</u></b>.
                </p>
                <p>
                  The promotion code will give a commission of 10% + 10,-. If there's no click, a conversion with a value of 100 should give a commission of 20,-.
                </p>
              </v-col>
          </v-row>

          <p>
            <a href="https://www.notion.so/makeinfluence/Troubleshooting-9dd0852ec2e445bfa3aa82a8c8618759" target="_blank">
              Read more about trousbleshooting
            </a>
          </p>

          <v-btn
            color="primary"
            class="mb-3 mt-3"
            outlined
            @click="refreshTableData()"
          >
            Update
          </v-btn>

          <v-btn
            color="error"
            class="mb-3 mt-3 ml-1"
            outlined
            @click="removeDemoSales()"
          >
            Remove conversions
          </v-btn>

          <div class="headline mb-3">Conversions</div>
          <v-data-table
            :headers="headers"
            :items="sales"
            :loading="loadingSales"
            hide-default-footer
            class="mb-5"
            :items-per-page="100"
          >
            <v-progress-linear
              slot="progress"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <template v-slot:body="{ items }">
              <tr v-if="sales.length === 0">
                <td class="text-center p-2" :colspan="headers.length">No results</td>
              </tr>
              <tbody>
                <tr v-for="item in items" :key="item.uuid">
                  <td>
                    <template v-if="item.click">
                      {{ item.click.created_at | utc_to_local_datetime }}
                    </template>
                  </td>
                  <td>{{ item.created_at | utc_to_local_datetime }}</td>
                  <td>{{ item.unique_key }}</td>
                  <td>{{ item.value | local_numbers }}</td>
                  <td>{{ item.commission | local_numbers }}</td>
                  <td>
                    <v-icon
                      v-if="item.influencer_promotion_code_id"
                      color="success"
                      small
                      >fal fa-check</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <div class="headline">Clicks</div>
          <p class="mb-3">
            <router-link :to="{ name: 'report.clicks', query: { business_id: business.uuid }}">
              See more clicks
            </router-link>
          </p>
          <clicks-data-table
             :items="clickItems"
             :loading="loadingClicks"
             class="mb-5"
          ></clicks-data-table>

          <div class="headline">Pixel log</div>
          <p class="mb-3">
            <router-link :to="{ name: 'conversion_logs', query: { search: business.uuid }}">
              See more logs
            </router-link>
          </p>
          <conversion-log-data-table
             :items="logItems"
             :loading="loadingLogs"
             class="mb-5"
          ></conversion-log-data-table>

          <v-btn class="mr-2" @click="step = 2">
            Back
          </v-btn>
          <v-btn
            v-if="! business.pixel_approved_at"
            color="primary"
            @click="confirmDialog = true"
          >
            Confirm pixel
          </v-btn>

          <v-btn
            v-if="
              business.pixel_approved_at &&
                !business.promotion_code_tracking_approved_at
            "
            color="primary"
            @click="promotionCodeConfirmDialog = true"
          >
            Bekræft rabat kode
          </v-btn>

          <confirm-dialog
            v-model="confirmDialog"
            :loading="loading"
            title="Bekræft pixel tracking"
            text="Bekræft at pixlen er korrekt indsat og udregner korrekt kommission."
            @confirm="confirmPixel()"
          ></confirm-dialog>

          <confirm-dialog
            :loading="loading"
            v-model="promotionCodeConfirmDialog"
            title="Bekræft rabat kode tracking"
            text="Bekræft at pixel med rabat kode er korrekt indsat og udregner korrekt kommission."
            @confirm="confirmPromotionCode()"
          ></confirm-dialog>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import copy_clipboard from "@/helpers/mixins/copy_clipboard";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import { mapActions } from "vuex";
import ConversionLogDataTable from "../data-tables/ConversionLogDataTable";
import ClicksDataTable from "../data-tables/ClicksDataTable";

export default {
  components: { ClicksDataTable, ConversionLogDataTable, ConfirmDialog },
  mixins: [copy_clipboard],
  filters: { local_numbers, utc_to_local_datetime },
  data: () => ({
    step: 1,
    shopOptions: [
      "Dandomain classic",
      "Dandomain hosted",
      "Magento 1.7-1.9",
      "Magento 2",
      "Prestashop",
      "Shopify",
      "Scannet",
      "Shoporama",
      "Smartweb",
      "Wannafind",
      "Woocommerce",
      "Andet"
    ],
    isWebshop: false,
    selectedShop: "",
    business: {},
    campaign: {},
    promotionCode: {},
    valueVar: "",
    uidVar: "",
    promotionCodeVar: "",
    onlyBid: false,
    sales: [],
    logItems: [],
    clickItems: [],
    loadingSales: false,
    loadingLogs: false,
    loadingClicks: false,
    confirmDialog: false,
    promotionCodeConfirmDialog: false,
    redirectLink: "",
    headers: [
      { text: "Klik", value: "click.created_at" },
      { text: "Tidspunkt", value: "created_at" },
      { text: "Ordre ID", value: "uid" },
      { text: "Værdi", value: "value" },
      { text: "Kommission", value: "commission" },
      { text: "Rabat kode", sortable: false }
    ],
    loading: false
  }),
  computed: {
    imagePixel() {
      let pixel = "";
      let value = "";
      let uid = "";
      let promotionCodeVar = "";

      if (this.valueVar) {
        value = "&value=" + this.valueVar;
      } else {
        value = "&value=[INSERT-ORDER-VALUE]";
      }

      if (this.uidVar) {
        uid = "&uid=" + this.uidVar;
      } else {
        uid = "&uid=[INSERT-ORDER-ID]";
      }

      if (this.promotionCodeVar) {
        promotionCodeVar = "&promotion_code=" + this.promotionCodeVar;
      }

      if (this.selectedShop === "Shopify") {
        pixel += "{% if first_time_accessed %}\n";
      }

      pixel +=
        '<img src="https://system.makeinfluence.com/p?bid=' +
        this.business.uuid +
        value +
        uid +
        promotionCodeVar +
        '">';

      if (this.selectedShop === "Shopify") {
        pixel += "\n{% endif %}";
      }

      return pixel;
    },
    trackingLink() {
      let influencerChannel = this.campaign.influencers[0].influencer
        .channels[0];

      // let domain = 'https://' + this.settings.app_domain;
      let domain = "https://system.makeinfluence.com";

      let url = domain + "/t";

      url += "?ic=" + influencerChannel.uuid;

      url += "&c=" + this.campaign.uuid;

      if (this.redirectLink) {
        url += "&u=" + encodeURIComponent(this.redirectLink);
      }

      return url;
    }
  },
  watch: {
    selectedShop() {
      this.onlyBid = false;

      if (this.selectedShop === "Dandomain classic") {
        this.valueVar = "[[SubTotalExclShippingAndPaymentFeesInclVAT]]";
        this.uidVar = "[[OrderID]]";
        this.promotionCodeVar = "";
      } else if (this.selectedShop === "Dandomain hosted") {
        this.valueVar = "%OrderTotalInclVatExclFee%";
        this.uidVar = "%OrderId%";
        this.promotionCodeVar = "";
      } else if (this.selectedShop === "Scannet") {
        this.valueVar = "$Order.TotalInclVatExclCosts$";
        this.uidVar = "$Order.Number$";
        this.promotionCodeVar = "";
      } else if (
        this.selectedShop === "Magento 2" ||
        this.selectedShop === "Magento 1.7-1.9" ||
        this.selectedShop === "Prestashop"
      ) {
        this.onlyBid = true;
        this.valueVar = "";
        this.uidVar = "";
        this.promotionCodeVar = "";
      } else if (this.selectedShop === "Smartweb") {
        this.valueVar = "%OrderTotalInclVatExclFee%";
        this.uidVar = "%OrderId%&%";
        this.promotionCodeVar = "";
      } else if (this.selectedShop === "Wannafind") {
        this.valueVar = "%OrderTotalInclVatExclFee%";
        this.uidVar = "%OrderId%";
        this.promotionCodeVar = "";
      } else if (this.selectedShop === "Shopify") {
        this.valueVar = "{{ subtotal_price | money_without_currency }}";
        this.uidVar = "{{ order_number }}";
        this.promotionCodeVar = "{{ order.discounts[0].code }}";
      } else if (this.selectedShop === "Woocommerce") {
        this.valueVar = "{order_subtotal}";
        this.uidVar = "{order_number}";
        this.promotionCodeVar = "";
      } else if (this.selectedShop === "Shoporama") {
        this.valueVar = '<{$order->getPrice()||number_format:2:".":""}>';
        this.uidVar = "<{$order->getWebshopOrderId()}>";
        this.promotionCodeVar = "";
      } else {
        this.valueVar = "";
        this.uidVar = "";
        this.promotionCodeVar = "";
      }
    }
  },
  methods: {
    ...mapActions("core", ["getBusiness", "getSales", "getConversionLogs", "getClicks"]),
    ...mapActions("moderator", [
      "setPromotionCodeApprovalUpdate",
      "setPromotionCodeApprovalCreate",
      "setBusinessApprovalUpdate",
      "setBusinessApprovalCreate"
    ]),

    startProcess() {
      this.loading = true;
      if (!this.business.pixel_approved_at) {
        this.getDemoCampaign();
      } else {
        this.getDemoPromotionCode();
      }
    },
    getDemoCampaign() {
      if (!this.business.pixel_approved_at) {
        let data = {
          business_uuid: this.business.uuid
        };
        this.setBusinessApprovalCreate(data).then(
          response => {
            this.campaign = response;
            if (!this.business.promotion_code_tracking_approved_at) {
              this.getDemoPromotionCode();
            } else {
              this.loading = false;
              this.step = 2;
            }
          },
          () => {
            this.setSnackError("Fejl");
          }
        );
      }
    },
    getDemoPromotionCode() {
      if (!this.business.promotion_code_tracking_approved_at) {
        const data = {
          business_uuid: this.business.uuid
        };
        this.setPromotionCodeApprovalCreate(data).then(
          response => {
            this.promotionCode = response;
            this.loading = false;
            this.step = 2;
          },
          () => {
            this.setSnackError("Fejl");
          }
        );
      }
    },
    refreshTableData() {
      this.getAllSales();
      this._getConversionLogs();
      this._getClicks();
    },
    getAllSales() {
      this.loadingSales = true;
      this.sales = [];
      const data = {
        business_uuid: this.business.uuid,
        is_demo: true,
        limit: 10
      };
      this.getSales(data).then(
        sales => {
          this.sales = sales.response;
          this.loadingSales = false;
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    _getConversionLogs() {
      if (this.loadingLogs) {
        return;
      }

      this.loadingLogs = true;

      const filters = {
        limit: 10,
        search: this.business.uuid
      };

      this.getConversionLogs(filters).then(response => {
        this.logItems = response.data;
        this.loadingLogs = false;
      });
    },
    _getClicks() {
      if (this.loadingClicks) {
        return;
      }

      this.loadingClicks = true;

      const params = {
        business_id: this.business.uuid,
        limit: 10,
      };

      this.getClicks(params).then(response => {
        this.loadingClicks = false;
        this.clickItems = response.data;
      });
    },
    removeDemoSales() {
      const data = {
        business_uuid: this.business.uuid,
        remove_sales: true
      };

      this.setBusinessApprovalCreate(data).then(
        () => {
          this.refreshTableData();
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    async confirmPixel() {
      this.loading = true;
      const params = {
        business_uuid: this.business.uuid
      };
      await this.setBusinessApprovalUpdate(params)
        .then(() => {
          this.setSnackSuccess("Success");
          this.confirmDialog = false;
          this.business.pixel_approved_at = this.$moment.toString();
        })
        .catch(() => {
          this.setSnackError("Fejl");
        });
      this.loading = false;
    },
    async confirmPromotionCode() {
      this.loading = true;
      const params = {
        business_uuid: this.business.uuid
      };
      await this.setPromotionCodeApprovalUpdate(params).then(
        () => {
          this.setSnackSuccess("Success");
          this.promotionCodeConfirmDialog = false;
          this.business.promotion_code_tracking_approved_at = this.$moment.toString();
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
      this.loading = false;
    }
  },
  created() {
    let id = this.$route.params.id;
    this.getBusiness({ business_uuid: id }).then(
      response => {
        this.business = response;
        this.redirectLink =
          response.website.indexOf("http") !== -1
            ? response.website
            : "https://" + response.website;
      },
      () => {
        this.setSnackError("Fejl");
        this.$router.push({ name: "application.business.list" });
      }
    );
  }
};
</script>
